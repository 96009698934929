.metal-container {
    height: 70vh;
    padding: 2rem;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4); /* Darken image background */
    color: #fff;
    text-align: center;
    overflow: hidden;
    z-index: 1;
}

.image-slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; /* Behind text */
    overflow: hidden; /* Hide overflow for smooth transitions */
}

.slider-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures image covers the whole area */
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 1.5s ease-in-out; /* Smooth fade transition */
}

.slider-image.active {
    opacity: 1; /* Image becomes fully visible */
}

.metal-container h1 {
    font-size: 4rem;
    color: #fff;
    margin-top: -100px;
    text-align: center;
    z-index: 1;
}

.metal-container p {
    margin-top: 8px;
    font-size: 1.2rem;
    color: #fff;
    text-align: center;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    z-index: 1;
}

.metal-btns {
    margin-top: 32px;
    display: flex;
    z-index: 1;
}

.metal-btns .btn {
    margin: 6px;
}

/* Tablet and small desktop view adjustments */
@media screen and (max-width: 960px) {
    .metal-container h1 {
        font-size: 3rem;
        margin-top: -150px;
    }

    .metal-container p {
        font-size: 1rem;
    }
}

/* Mobile view adjustments */
@media screen and (max-width: 768px) {
    .metal-container h1 {
        font-size: 2.5rem;
        margin-top: -100px;
    }

    .metal-container p {
        font-size: 0.9rem;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;
    }

    .btn {
        width: 100%;
    }
}