/* UberUns.css */

/* Container for the entire Uber Uns section */
.uber-uns-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Main Heading */
  .uber-uns-heading {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }
  
  /* Section Heading (Dienstleistungen, Technische Expertise, etc.) */
  .uber-uns-section h2 {
    text-align: center;
    font-size: 2rem;
    margin-top: 2rem;
  }
  
  /* Services container */
  .uber-uns-services {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
  }
  
  /* Wrapper for service items */
  .service-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    max-width: 1200px;
    margin-top: 2rem;
  }
  
  /* Individual service item */
  .service {
    flex: 1 1 45%;
    min-width: 280px;
    background: #f9f9f9;
    padding: 1.5rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .service h3 {
    text-align: left;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  
  .service p {
    text-align: left;
    font-size: 1rem;
    line-height: 1.6;
  }
  
  /* Responsive adjustments */
  @media screen and (max-width: 768px) {
    /* Stack services vertically on smaller screens */
    .service-wrapper {
      flex-direction: column;
    }
  
    .service {
      min-width: 100%;
    }
  }
  