/* Reset and Global Styles */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
}

body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

/* Container Styles */
.kontakt-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem; /* Increase gap to create more breathing room */
    text-align: center;
    padding: 2rem 1rem;
    background: #f9f9f9; /* Light background for better contrast */
    min-height: 100vh; /* Ensure the container takes at least the full viewport height */
}

.kontakt-map-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin: 2rem 0;
    flex-wrap: wrap; /* Allow wrap for smaller screens */
    gap: 1rem;
}

.kontakt {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
    width: 48%; /* Reduce width slightly for better spacing */
    max-width: 500px;
    padding: 1rem;
    background: #fff; /* Card-like style for contrast */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    border-radius: 10px;
}

.kontakt.animate {
    opacity: 1;
    transform: translateY(0);
}

.kontakt-location,
.kontakt-info {
    width: 100%;
    margin-bottom: 1rem;
}

.kontakt h3 {
    margin-bottom: 1rem;
    font-size: 1.8rem;
    color: #333;
}

.kontakt p,
.kontakt a {
    font-size: 1rem;
    color: #444;
    margin: 0.5rem 0;
    text-decoration: none;
}

.kontakt a:hover {
    color: #007bff;
}

.kontakt-location address {
    font-style: normal;
    font-size: 1rem;
    color: #666;
}

.map-container {
    width: 48%;
    max-width: 600px;
    margin-bottom: 20px;
    height: 450px;
    border: 1px solid #ddd;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

/* Icon Styles */
.kontakt-info i {
    margin-right: 10px;
    font-size: 1.2rem;
    color: #007bff; /* Add color for a pop effect */
}

/* Media Queries for Responsive Design */
@media only screen and (max-width: 1150px) {
    .kontakt-map-container {
        flex-direction: column;
        align-items: center;
    }

    .map-container,
    .kontakt {
        width: 100%;
        max-width: 600px;
        margin-bottom: 6rem;
    }

    .map-container {
        height: 350px; /* Adjust height for smaller screens */
    }
}

@media only screen and (max-width: 480px) {
    .map-container {
        margin-bottom: 9rem;
    }

    .kontakt h3 {
        font-size: 1.5rem;
    }

    .kontakt p {
        font-size: 0.9rem;
    }

    .kontakt-info i {
        font-size: 1rem;
    }

    .map-container {
        height: 300px;
    }
}
