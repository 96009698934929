/* Produkte.css */

/* Container Styles */
.produkte-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem;
    background-color: #f5f5f5;
    color: #333;
    font-family: 'PT Sans', sans-serif;
    min-height: calc(90vh - 200px); /* Ensure the container takes at least the full viewport height minus the footer height */
}

/* Title Styles */
.produkte-title {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: black;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1.5px;
}

/* Content Styles */
.produkte-content {
    max-width: 800px;
    background: #ffffff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    line-height: 1.8;
    text-align: left;
    color: #555;
}

.produkte-content p {
    margin-top: 1.5rem;
    font-size: 1rem;
}

/* Button Styles */
.kontakt-button {
    margin-top: 2rem;
    padding: 0.8rem 2rem;
    background-color: #1f98f4;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    align-items: center;
}

.kontakt-button:hover {
    background-color: #1a7bcc;
}

/* Footer Styles */
footer {
    margin-top: auto;
    width: 100%;
    padding-top: 2rem;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .produkte-container {
        padding: 2rem;
    }

    .produkte-title {
        font-size: 2rem;
    }

    .produkte-content {
        font-size: 0.95rem;
    }
}

@media (max-width: 480px) {
    .produkte-container {
        padding: 1.5rem;
    }

    .produkte-title {
        font-size: 1.8rem;
    }

    .produkte-content {
        font-size: 0.9rem;
        padding: 1.5rem;
    }
}
