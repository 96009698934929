/* Container and Layout */
.referenzen-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
  background: #f9f9f9;
}

.referenzen-title {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 2rem;
  text-align: center;
}

.referenzen-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  max-width: 1200px;
  width: 100%;
}

/* Individual Item Styles */
.referenzen-item {
  width: calc(33.333% - 20px); /* Default to 3 items per row with gap */
  max-width: 400px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.referenzen-item:hover {
  transform: translateY(-5px);
}

.image-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 66%; /* Aspect ratio to control image height */
  overflow: hidden;
  border-radius: 10px;
}

.gallery-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease, filter 0.3s ease;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Darken the image on hover */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  opacity: 0; /* Hidden by default */
  transition: opacity 0.3s ease;
  color: #fff;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 10px;
}

.overlay h3 {
  font-size: 1.5rem;
  margin: 0;
}

/* Hover Effect */
.image-container:hover .gallery-img {
  filter: brightness(0.6); /* Darken image slightly when hovering */
  transform: scale(1.05); /* Slight zoom effect */
}

.image-container:hover .overlay {
  opacity: 1; /* Show overlay text on hover */
}

/* Responsive Design */
@media only screen and (max-width: 1024px) {
  .referenzen-item {
      width: calc(50% - 20px); /* Two items per row */
  }
}

@media only screen and (max-width: 768px) {
  .referenzen-item {
      width: 100%; /* One item per row */
  }
}
