* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
  }

  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal scrolling */
  }
  .home,
  .produkte,
  .impressum,
  
  
  .produkte {
    background-position: center;
    background-size: fill;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }

  .impressum {
    background-position: center;
    background-size: fill;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }

  h1 {
    font-size: 3rem;
    font-weight: 700;
    color: black;
    text-align: center;
    margin-top: 10px;
  }
  