/* Impressum.css */

/* Container Styles */
.impressum-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem;
    background-color: #f9f9f9;
    color: #333;
    font-family: 'PT Sans', sans-serif;
}

/* Title Styles */
.impressum-title {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: black;
    text-align: center;
}

/* Content Styles */
.impressum-content {
    max-width: 800px;
    line-height: 1.6;
    text-align: left;
}

.impressum-content h2, .impressum-content h3, .impressum-content h4 {
    margin-top: 2rem;
    color: black;
}

.impressum-content p {
    margin-top: 1rem;
    color: #555;
    font-size: 1rem;
}

/* Link Styles */
.impressum-content a {
    color: black;
    text-decoration: none;
}

.impressum-content a:hover {
    text-decoration: underline;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .impressum-container {
        padding: 2rem;
    }

    .impressum-title {
        font-size: 2rem;
    }

    .impressum-content {
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .impressum-container {
        padding: 1.5rem;
    }

    .impressum-title {
        font-size: 1.8rem;
    }

    .impressum-content {
        font-size: 0.85rem;
    }
}
