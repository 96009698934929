.footer-container {
    background-color: black;
    color: #fff;
    padding: 4rem 0 2rem 0;
    text-align: center;
  }
  
  .footer-contact a {
    color: #1f98f4;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .footer-contact a:hover {
    color: #0f7ac1;
  }
  
  .footer-heading {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .footer-location {
    margin-top: 1.5rem; /* Add some spacing between contact and location */
}
  
  .footer-location address {
    font-style: normal;
  }

  .footer-contact-details i {
    margin-right: 10px; /* Adjust space between icon and text */
    font-size: 1.2rem; /* Optionally, adjust the icon size */
}