.navbar {
  display: flex;
  justify-content: space-between; /* Distribute space between logo and nav items */
  align-items: center; /* Align items vertically in the center */
  background-color: black; /* Adjust background color */
  padding: 10px 20px; /* Spacing inside the navbar */
  position: relative;
  z-index: 1000;
  width: 100%;
  max-width: 100%;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Ensure vertical alignment */
  width: 100%;
}

.navbar-logo {
  width: 100px; /* Keep the logo a reasonable size */
  height: auto;
  display: block;
  margin-right: 20px; /* Space between logo and nav menu */
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: flex; /* Change grid to flex */
  justify-content: flex-end; /* Align menu items to the right */
  list-style: none;
  margin: 0; /* Remove extra margins */
}

.nav-item {
  margin-left: 20px; /* Space between nav items */
}

.nav-links {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  border-bottom: 2px solid #fff;
  transition: 0.3s ease;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none; /* Hidden by default */
}

@media screen and (max-width: 960px) {
  .nav-menu {
    z-index: 1001;  
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 50px;
    opacity: 1;
    left: -100%;
    height: 100vh;
    background-color: #242222;
    transition: 0.5s;
    position: fixed;
  }

  .nav-menu.active {
    background: #242222;
    position: absolute;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    width: 100%;
    height: 100vh;
    top: 50px;
  }
  

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: relative; /* Fix overlap issue */
    top: 0;
    left: 0;
    transform: none; /* Remove translate */
    width: 80px; /* Adjust size for mobile */
  }

  .menu-icon {
    display: block; /* Show the menu icon on smaller screens */
    position: absolute;
    top: 50%; /* Align vertically with the navbar */
    right: 20px; /* Adjust to prevent overflowing */
    transform: translateY(-50%); /* Correct centering */
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}